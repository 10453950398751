import { useTranslation } from 'react-i18next'

export type Option = {
  label: string
  value: any
}

type Props = {
  selected: Option
  onChange: (option: Option) => void
  options: Option[]
}

export const ButtonGroup = ({ selected, onChange, options }: Props) => {
  return (
    <div className="flex flex-grow flex-wrap gap-4 bg-neutral-100 p-1 rounded-lg">
      {options.map((option) => {
        const isActive = option.value === (selected?.value !== undefined ? selected?.value : options[0].value)
        return (
          <button
            key={option.label}
            onClick={() => onChange(option)}
            className={`flex flex-grow font-tag md:font-body2 justify-center p-3 rounded-md border border-solid transition-all select-none ${isActive ? 'bg-base-white border-base-black border-opacity-15' : 'border-neutral-100'}`}
          >
            {option.label}
          </button>
        )
      })}
    </div>
  )
}
