
import { useTranslation } from 'react-i18next'
import ReactSelect from 'react-select'

export type SelectItem = {
  value: string | number,
  label: string
}

type SelectProps = {
  value?: SelectItem,
  label?: string,
  options: SelectItem[],
  onChange: (value: SelectItem) => void,
  onBlur?: () => void,
  placeholder?: string
  isClearable?: boolean
  loading?: boolean
  error?: string
  controlClassNames?: string
}

export const Select = ({ value, label, options, onChange, onBlur, placeholder, isClearable, loading, error, controlClassNames }: SelectProps) => {
  const { t } = useTranslation()

  return <div className='flex flex-col gap-2'>
    {label ? <label>{label}</label> : null}
    {loading ? <p>{t('loading')}</p> :
      <ReactSelect
        classNames={{
          control() {
            const extraStyling = error ? '!border-error' : ''
            return `${controlClassNames} ${extraStyling}`
          }
        }}
        value={value}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        isClearable={isClearable}
        openMenuOnClick={true}
      />
    }
    {error && <span className="text-error">{error}</span>}
  </div>
}