import { useTranslation } from "react-i18next"

type Option<T> = {
  label: string
  value: T
}

type Props<T> = {
  selectedOptions: T[]
  onChange: (selectedOptions: T[]) => void
  options: Option<T>[]
}

export const MultiSelect = <T,>({ options, selectedOptions, onChange }: Props<T>) => {
  const handleOnChange = (option: T) => {
    const isSelected = selectedOptions.includes(option)
    if (isSelected) {
      onChange(selectedOptions.filter((selectedOption) => selectedOption !== option))
    } else {
      onChange([...selectedOptions, option])
    }
  }

  return (
    <div className="flex flex-wrap flex-grow gap-4">
      {options.map((option) => {
        const isSelected = selectedOptions.includes(option.value)
        return (
          <button
            key={option.label}
            onClick={() => handleOnChange(option.value)}
            className={`flex flex-grow justify-center p-3 rounded-md border border-solid transition-all ${isSelected ? 'bg-base-white font-semibold border-base-black border-opacity-15' : 'bg-neutral-100 border-neutral-100'}`}
          >
            {option.label}
          </button>
        )
      })}
    </div>
  )
}