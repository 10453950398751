import {
  useQuery
} from 'react-query'

import { AnalyzerResult, MedicationSchedule } from '../form/form.types'
import { StaleTime } from '@/constants/Staletime'
import { useAxios } from '@/hooks/useAxios'

export type AnalyzerResponse = {
  medicationSchedule: MedicationSchedule,
  results: AnalyzerResult[]
}

type AnalyzerProps = {
  healthcareProfessionalId: string,
  patientId: string
}

export const useAnalyzer = ({
  healthcareProfessionalId,
  patientId }: AnalyzerProps) => {
  const axios = useAxios()
  const url = `analyzer/${healthcareProfessionalId}/${patientId}`

  const { data, isLoading, isFetching, isError, error, refetch } = useQuery({
    queryKey: ['analyzer', healthcareProfessionalId, patientId],
    queryFn: async () => {
      const response = await axios.get<AnalyzerResponse>(url)
      return response.data
    },
    select: (response) => {
      return {
        ...response, results: response.results.sort((a, b) => b.severity.localeCompare(a.severity))
      }
    },
  })

  console.log(isError, error, data,)

  return { analysis: data, isLoading, isFetching, error, refetch }
}
