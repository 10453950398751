import { isLocalDevelopment } from "@/config";

export enum Languages {
  nl = 'nl-BE',
  fr = 'fr-BE',
  en = 'en-GB'
}

export const SupportedLanguages = isLocalDevelopment ? [
  {
    label: 'Nederlands',
    shortLabel: 'Nl',
    value: Languages.nl,
  },
  {
    label: 'Français',
    shortLabel: 'Fr',
    value: Languages.fr,
  },
  {
    label: 'Debug',
    shortLabel: 'Dbg',
    value: 'debug',
  }
] : [
  {
    label: 'Nederlands',
    shortLabel: 'Nl',
    value: Languages.nl,
  },
  {
    label: 'Français',
    shortLabel: 'Fr',
    value: Languages.fr,
  },
]

