import { MedicationSchedule } from '@/form/form.types'
import { useFormikContext } from 'formik'
import { Arrow, General } from 'untitledui-js'
import { getErrorMessage, getFieldTouched } from '../utils/formik'

type Props = {
  value: number
  fieldName?: string
  onChange: (value: number) => void
  onBlur?: () => void
  placeholder?: string
  min?: number
  max?: number
  step?: number
  wide?: boolean
}

export const NumericInput = ({
  value,
  fieldName,
  onChange,
  onBlur,
  placeholder,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  step = 1,
  wide = false,
}: Props) => {
  const { touched, errors, setFieldValue, setFieldTouched } = useFormikContext()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ? parseInt(event.target.value) : undefined
    if (onChange) {
      onChange(newValue)
      return
    }

    if (event.target.value === '') {
      if (fieldName) {
        setFieldValue(fieldName, undefined)
      }
      onChange(undefined)
    }
    if (isNaN(newValue)) {
      return
    }
    if (fieldName) {
      setFieldValue(fieldName, newValue)
    }
  }

  const handleBlur = () => {
    if (fieldName) {
      setFieldTouched(fieldName, true)
    }
    onBlur && onBlur()
  }

  const increment = () => {
    if (fieldName) {
      setFieldTouched(fieldName, true)
    }
    if (!value) {
      onChange(min)
      return
    }
    onChange(Math.min(max, value + step))
  }

  const decrement = () => {
    if (fieldName) {
      setFieldTouched(fieldName, true)
    }
    if (!value) {
      onChange(min)
      return
    }
    onChange(Math.max(min, value - step))
  }

  const errorMessage = fieldName ? getErrorMessage(errors, fieldName) : ''
  const showError = fieldName ? errorMessage && getFieldTouched(touched, fieldName) : false

  return (
    <div className="relative">
      <div
        className={`flex items-center rounded-lg bg-neutral-100 ${wide ? 'flex-grow' : ''}`}
      >
        <input
          type="number"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          className={`flex font-tag md:font-body2 font-bold pl-2 md:pl-4 py-2  ${wide ? 'flex-grow' : 'min-w-[30px] flex-grow md:w-[60px]'}`}
          min={min}
          max={max}
          step={step}
          placeholder={placeholder}
        />
        <div className="flex flex-col pr-2 md:pr-4 py-2">
          <button
            onClick={increment}
            className="h-5 opacity-50"
            aria-label="Increment value"
          >
            <Arrow.ChevronUp height={20} />
          </button>
          <button
            onClick={decrement}
            className="h-5 opacity-50"
            aria-label="Decrement value"
          >
            <Arrow.ChevronDown height={20} />
          </button>
        </div>
      </div>
      {
        showError ? (
          <span className="absolute -bottom-8 text-error">{errorMessage}</span>
        ) : null
      }
    </div>
  )
}
