export enum IntervalType {
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
}

export enum Weekdays {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export type Product = {
  productId: number,
  productDescription: string
  atc?: string
}

export type MedicationFormData = {
  id: string
  order?: number
  product?: Product
  dosage: Dosage
  interval: Interval
}

export type Dosage = {
  times: number
  dose: number
}

export type Interval = {
  interval: number
  intervalType: IntervalType
  intervalLabel: string
  weekdays?: Weekdays[]
}