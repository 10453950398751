import { Button } from '@/components/button/Button'
import { StepLayout } from '@/components/layouts/StepLayout'
import { useFormikContext } from 'formik'
import { MedicationSchedule } from '../form.types'
import { TextInput } from '@/components/input/TextInput'
import { useGenders } from '../../api/useGenders'
import { Select } from '@/components/input/Select'
import { useTranslation } from 'react-i18next'
import { LocalStorageKeys } from '@/constants/LocalStorageKeys'
import { NumericInput } from '@/components/input/NumericInput'
import { ButtonGroup } from '@/components/button-group/ButtonGroup'
import { usePersonTypes } from '@/api/usePersonTypes'
import { useMedicationProfessionals } from '@/api/useMedicationProfessionals'

type Props = {
  onNext: () => void
  onPrevious: () => void
  isLoading?: boolean
}

export const AdditionalInfoStep = ({
  onNext,
  onPrevious,
  isLoading,
}: Props) => {
  const { t } = useTranslation()

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    validateForm,
    submitForm,
  } = useFormikContext<MedicationSchedule>()

  const {
    genders,
    isLoading: gendersLoading,
    error: genderError,
  } = useGenders()

  const {
    medicationProfessionals,
    isLoading: medicationProfessionalLoading,
    error: medicationProfessionalError,
  } = useMedicationProfessionals()

  const handleNext = () => {
    setFieldTouched('patient.birthYear', true)
    setFieldTouched('patient.gender', true)
    setFieldTouched('healthcareProfessional.email', true)
    setFieldTouched('healthcareProfessional.type', true)

    validateForm().then(async (errors) => {
      // No errors after validating, proceed to next step
      if (Object.keys(errors).length === 0) {
        localStorage.setItem(LocalStorageKeys.FormState, JSON.stringify(values))
        await submitForm()
      }
    })
  }

  if (gendersLoading || medicationProfessionalLoading) {
    return null
  }

  return (
    <StepLayout title={t('form.additionalInfo.title')}>
      <div className="flex flex-col justify-between gap-12 pb-8 md:gap-16">
        <div className="flex flex-col gap-4 md:gap-12">
          <p className="font-body text-grey-700">
            {t('form.additionalInfo.description')}
          </p>

          <div className="flex flex-col gap-3">
            <p className="font-title3 md:font-title2">{t('form.patient')}</p>
            <div className="flex flex-col gap-2 md:gap-10 md:flex-row">
              <div className="flex flex-grow flex-col gap-2">
                <label htmlFor="patient.birthYear" className="font-body2">
                  {t('form.patient.birthYear')}
                </label>
                <NumericInput
                  value={values.patient.birthYear}
                  onChange={(value) => {
                    console.log('setting value', value)
                    setFieldValue('patient.birthYear', value)
                  }
                  }
                  fieldName='patient.birthYear'
                  min={1900}
                  max={new Date().getFullYear()}
                  wide
                  placeholder={t('form.patient.birthYear')}
                />
              </div>
              {!genders ? null : (
                <div className="flex flex-grow flex-col gap-2">
                  <label htmlFor="patient.gender" className="font-body2">
                    {t('form.patient.gender')}
                  </label>
                  <ButtonGroup
                    selected={values.patient.gender}
                    onChange={(value) => setFieldValue('patient.gender', value)}
                    options={genders}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <p className="font-title2">{t('form.yourData')}</p>
            <p className="font-body2 text-grey-700">
              {t('form.yourData.description')}
            </p>
            <div className="flex flex-col gap-6 mt-4">
              <div className="flex flex-col gap-2">
                <TextInput
                  value={values.healthcareProfessional?.emailAddress}
                  label={t('form.healthcareProfessional.email')}
                  type="text"
                  fieldName="healthcareProfessional.emailAddress"
                />
              </div>

              {!medicationProfessionals ? null : (
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="healthcareProfessional.type"
                    className="font-body2"
                  >
                    {t('form.healthcareProfessional.type')}
                  </label>
                  <ButtonGroup
                    selected={values.healthcareProfessional?.type}
                    onChange={(value) =>
                      setFieldValue('healthcareProfessional.type', value)
                    }
                    options={medicationProfessionals}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-6">
          <Button onClick={onPrevious} disabled={isLoading}>
            {t('form.previous')}
          </Button>
          <Button
            onClick={handleNext}
            disabled={isLoading || Object.keys(errors).length > 0}
            isLoading={isLoading}
          >
            {t('form.next')}
          </Button>
        </div>
      </div>
    </StepLayout>
  )
}
