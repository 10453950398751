import {
  AutocompleteComponents,
  autocomplete,
  getAlgoliaResults,
} from '@algolia/autocomplete-js'
import { createElement, Fragment, useEffect, useRef, useState } from 'react'
import { createRoot, Root } from 'react-dom/client'
import type { Hit } from '@algolia/client-search'

import algoliasearch from 'algoliasearch/lite'

import '@algolia/autocomplete-theme-classic'

import { AlogliaMedication } from '@/form/form.types'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '@/translations/hooks/useLanguage'
import { useConfig } from '@/config'
import { Product } from '../forms/medication.types'

export type MedicationHit = Hit<AlogliaMedication>

type MedicationAutocompleteProps = {
  value: Product
  onChange: (value: Product) => void
  error?: string
}

export function MedicationAutocomplete({
  value,
  onChange,
  error,
}: MedicationAutocompleteProps) {
  const { t } = useTranslation()
  const config = useConfig()
  const { algoliaLanguage } = useLanguage()

  const searchClient = algoliasearch(config.algoliaAppId, config.algoliaApiKey)

  const containerRef = useRef<HTMLDivElement | null>(null)
  const panelRootRef = useRef<Root | null>(null)
  const rootRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (!containerRef.current) {
      return undefined
    }

    const search = autocomplete<MedicationHit>({
      container: containerRef.current,
      placeholder: t('form.medication.autocomplete.placeholder'),
      initialState: {
        query: value?.productDescription || '',
      },
      detachedMediaQuery: 'none',
      getSources({ query, setQuery }) {
        return [
          {
            sourceId: 'Products',
            getItems() {
              return getAlgoliaResults<MedicationHit>({
                searchClient,
                queries: [
                  {
                    indexName: 'Products',
                    query,
                    params: {
                      hitsPerPage: 20,
                    },
                  },
                ],
              })
            },
            templates: {
              header() {
                return (
                  <Fragment>
                    <span className="aa-SourceHeaderTitle">
                      {t('form.medication.autocomplete.subtitle')}
                    </span>
                    <div className="aa-SourceHeaderLine" />
                  </Fragment>
                )
              },
              item({ item, components }) {
                return (
                  <MedicationResult
                    hit={item}
                    components={components}
                    onClick={() => {
                      setQuery(item?.[`productDescription_${algoliaLanguage}`])
                      onChange({
                        productId: item?.productId,
                        productDescription:
                          item?.[`productDescription_${algoliaLanguage}`],
                      })
                    }}
                  />
                )
              },
              noResults() {
                return 'No matching products' //TODO add actual visualisation here
              },
            },
          },
        ]
      },
      renderer: { createElement, Fragment, render: () => { } },
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root

          panelRootRef.current?.unmount()
          panelRootRef.current = createRoot(root)
        }

        panelRootRef.current.render(children)
      },
      onReset: () => {
        onChange(undefined)
      },
      classNames: {
        form: `flex items-center bg-neutral-100 p-0 border rounded-md relative w-full ${error ? 'border-error' : 'border-gray-300'}`,
        detachedSearchButtonIcon: '!stroke-none color-neutral-400',
        submitButton: error ? 'stroke-error' : 'stroke-gray-300',
      },
      openOnFocus: true,
    })

    return () => {
      search.destroy()
    }
  }, [error, value])

  return (
    <>
      <div className="w-full relative" ref={containerRef}>
        {error && <span className="absolute -bottom-7 text-error">{error}</span>}
      </div>
    </>
  )
}

type MedicationResultProps = {
  hit: MedicationHit
  components: AutocompleteComponents
  onClick: () => void
}

const MedicationResult = ({
  hit,
  components,
  onClick,
}: MedicationResultProps) => {
  const { algoliaLanguage } = useLanguage()

  return (
    <p className="flex items-center h-full p-2 whitespace-pre" onClick={onClick}>
      <components.Highlight
        hit={hit}
        attribute={[`productDescription_${algoliaLanguage}`]}
      />
    </p>
  )
}
