import { Form } from 'react-router-dom'
import { IntroStep } from './steps/IntroStep'
import { MedicationStep } from './steps/MedicationStep'
import { AdditionalInfoStep } from './steps/AdditionalInfoStep'
import { ReportStep } from './steps/ReportStep'
import { FormSteps, useFormContext } from './context/FormContextProvider'
import { Formik } from 'formik'
import { MedicationSchedule } from './form.types'
import { useFormValidation } from './hooks/useFormValidation'
import { useMedicationSchedule } from './hooks/useMedicationSchedule'
import { useGenders } from '@/api/useGenders'
import { usePersonTypes } from '@/api/usePersonTypes'
import { PersistedForm } from '@/components/persisted-form/PersistedForm'
import { TermsAndConditionsScreen } from '@/terms-and-conditions/TermsAndConditions'
import { useCategories } from '@/api/useCategories'

export const MedipilotForm = () => {
  const { step, handleNext, handlePrevious, showTermsAndConditions } = useFormContext()

  const validationSchema = useFormValidation()
  const { submit, isLoading } = useMedicationSchedule()

  // Preload data
  useGenders()
  usePersonTypes()
  useCategories()

  // -- Handlers --
  const onSuccess = () => {
    handleNext()
  }

  if (showTermsAndConditions) {
    return <TermsAndConditionsScreen />
  }

  return (
    <Formik<MedicationSchedule>
      initialValues={{
        medicationSchema: [],
        patient: {},
        healthcareProfessional: {},
      }}
      validationSchema={validationSchema}
      validateOnBlur={true}
      validateOnMount={true}
      onSubmit={(values, actions) => {
        submit(values, onSuccess)
        actions.setSubmitting(false)
      }}
    >
      <PersistedForm>
        {step === FormSteps.Intro ? (
          <IntroStep />
        ) : step === FormSteps.Medication ? (
          <MedicationStep onNext={handleNext} />
        ) : step === FormSteps.Patient ? (
          <AdditionalInfoStep
            onNext={handleNext}
            onPrevious={handlePrevious}
            isLoading={isLoading}
          />
        ) : step === FormSteps.Report ? (
          <ReportStep onNext={handleNext} />
        ) : null}
      </PersistedForm>
    </Formik>
  )
}
