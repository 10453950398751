import { Button } from "@/components/button/Button"
import { StepLayout } from "@/components/layouts/StepLayout"
import { useAnalyzer } from "../../api/useAnalyzer"
import { AnalyzerResult, MedicationSchedule, Severity } from "../form.types"
import { use } from "i18next"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import { LocalStorageKeys } from "@/constants/LocalStorageKeys"
import { TermsAndConditionsForm } from "@/components/forms/TermsAndConditionsForm"
import { useState } from "react"
import { CrossGrid } from "@/components/cross-grid/CrossGrid"
import { ReportCard } from "@/components/report-card/ReportCard"
import { Report } from "@/components/report/Report"
import { useCategories } from "@/api/useCategories"

type Props = {
  onNext: () => void
}

export const ReportStep = ({ onNext }: Props) => {
  const { t } = useTranslation()
  const { resetForm } = useFormikContext()
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)

  const patientId = localStorage.getItem(LocalStorageKeys.PatientId)
  const healthcareProfessionalId = localStorage.getItem(LocalStorageKeys.HealthCareProfessionalId)

  const { analysis, isLoading, isFetching, error } = useAnalyzer({
    healthcareProfessionalId,
    patientId
  })

  const {
    categories,
    isLoading: categoriesLoading,
    error: categoriesError
  } = useCategories()


  const handleReset = () => {
    // Reset Form
    resetForm()
    localStorage.removeItem(LocalStorageKeys.FormState)
    localStorage.removeItem(LocalStorageKeys.FormData)
    localStorage.removeItem(LocalStorageKeys.PatientId)
    localStorage.removeItem(LocalStorageKeys.ExpirationTime)
  }

  const handleNext = (reset?: boolean) => {
    localStorage.removeItem(LocalStorageKeys.FormState)

    if (reset) {
      handleReset()
    }
    onNext()
  }

  const handleAcceptTerms = () => {
    setTermsAccepted(true)
  }

  if (!termsAccepted) {
    return <TermsAndConditionsForm onAccept={handleAcceptTerms} />
  }

  return (
    <StepLayout title={t('steps.report.title')}>
      {isLoading || categoriesLoading || isFetching ? <p className='font-body'>{t('loading')}</p> :
        error || categoriesError ? <div className="flex flex-col gap-4">
          <p className='font-body2 text-error'>{t('steps.report.error')}</p>
          <p className='font-body3 text-error'>{(error as any)?.message}</p>
          <Button variant='primary' full onClick={() => handleNext(true)}>
            <p className='font-body2 text-base-white text-center'>{t('form.restart')}</p>
          </Button>
        </div>
          :
          <>
            <div className="flex flex-col flex-grow gap-6">
              <p className="font-body2 md:font-body1">
                {t('steps.report.description', {
                  count: analysis?.results?.length
                })}
              </p>

              <CrossGrid data={analysis.results} categories={categories} />

              <Report analysis={analysis.results} categories={categories} />
            </div>
            <div className='w-full flex flex-col items-center gap-4 md:flex-row'>
              <Button variant='primary' full onClick={() => handleNext(true)}>
                <p className='font-body2 text-base-white text-center'>{t('form.restart')}</p>
              </Button>
              <Button variant='tertiary' full onClick={() => handleNext(false)}>
                <p className='font-body2 text-center'>{t('form.edit')}</p>
              </Button>
            </div>
          </>
      }

      <div className="pb-8 md:pb-20" />
    </StepLayout>
  )
}