export enum LocalStorageKeys {
  SelectedLanguage = 'SELECTED_LANGUAGE',
  IntroViewed = 'INTRO_VIEWED',
  HealthCareProfessionalId = 'HEALTHCARE_PROFESSIONAL_ID',
  PatientId = 'PATIENT_ID',
  FormState = 'FORM_STATE',
  FormData = 'FORM_DATA',
  TermsAccepted = 'TERMS_ACCEPTED',
  ExpirationTime = 'EXPIRATION_TIME',
}

export const EXPIRATION_TIME = 60 * 1000 // 1Hour