import { useMemo, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { General } from 'untitledui-js';
import { AnalyzerResult } from '@/form/form.types';

type Props = {
  title: string,
  analysis: AnalyzerResult[],
  children: React.ReactNode,
}

export const Collapsible = ({ title, children, analysis }: Props) => {
  const [isOpen, setIsOpen] = useState(true)

  const severityCounter = useMemo(() => {
    const counter = {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    }

    analysis?.forEach((result) => {
      counter[result.severity]++
    })

    return counter
  }, [analysis])

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  }

  return (
    <div className="flex flex-col gap-8 relative">
      <div className="flex items-center">
        <div className='flex flex-grow items-center gap-4 cursor-pointer' onClick={toggleOpen}>
          {isOpen ? <General.Minus /> : <General.Plus />}
          <p className="font-body1">{title}</p>
        </div>
        <div className='flex gap-2'>
          <p className="px-4 py-1 rounded-full font-body2 font-semibold bg-severity-d bg-opacity-50 text-text-severity-d">{severityCounter.D || '-'}</p>
          <p className="px-4 py-1 rounded-full font-body2 font-semibold bg-severity-c bg-opacity-50 text-text-severity-c">{severityCounter.C || '-'}</p>
          <p className="px-4 py-1 rounded-full font-body2 font-semibold bg-severity-b bg-opacity-50 text-text-severity-b">{severityCounter.B || '-'}</p>
          <p className="px-4 py-1 rounded-full font-body2 font-semibold bg-severity-a bg-opacity-50 text-text-severity-a">{severityCounter.A || '-'}</p>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="overflow-hidden"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
          >
            <div className='flex flex-col gap-7'>
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
