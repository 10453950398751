import 'the-new-css-reset/css/reset.css'
import { PageLayout } from './components/layouts/PageLayout'
import { Sidebar } from './form/components/Sidebar'
import { MedipilotForm } from './form/MedipilotForm'
import { useFormContext } from './form/context/FormContextProvider'

export const App = () => {
  const { resetForm } = useFormContext()

  return <PageLayout resetForm={resetForm} sidebar={<Sidebar />}>
    <MedipilotForm />
  </PageLayout>
}
