import { useTranslation } from 'react-i18next'
import { StepLayout } from '@/components/layouts/StepLayout';
import { MarkdownRenderer } from '@/components/markdown/MarkdownRenderer';
import { Arrow } from 'untitledui-js';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '@/form/context/FormContextProvider';

export const TermsAndConditionsScreen = () => {
  const { t } = useTranslation()
  const { toggleTermsAndConditions } = useFormContext()

  return (
    <div className='flex flex-col'>
      <div className='flex items-center gap-1 cursor-pointer md:max-w-[520px] lg:max-w-[780px]' onClick={toggleTermsAndConditions}>
        <Arrow.ArrowLeft />
        <p className='font-body2'>{t('back')}</p>
      </div>
      <StepLayout title={t('steps.termsAndConditions.title')}>
        <div className="flex flex-col min-h-0 flex-grow gap-10">
          <div className="flex flex-col gap-4 flex-grow overflow-y-auto bg-neutral-100 rounded-lg px-6 py-10">
            <MarkdownRenderer
              markdownText={t('termsAndConditions')
              }
            />
          </div>
        </div>
      </StepLayout >
    </div>
  )
}
