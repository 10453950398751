import { createContext, useContext, useEffect, useState } from 'react'

import type { ReactNode } from 'react'

export type MedipilotConfig = {
  api: string
  apiKey: string
  env: string
  algoliaAppId: string
  algoliaApiKey: string
}

export const isProduction = import.meta.env.VITE_WEBAPP_ENV === 'PRD'
export const isAcceptance = import.meta.env.VITE_WEBAPP_ENV === 'ACC'
export const isLocalDevelopment = !isProduction && !isAcceptance

const fetchConfig = async (): Promise<MedipilotConfig> => {
  const config = {
    api: import.meta.env.VITE_WEBAPP_API,
    apiKey: import.meta.env.VITE_WEBAPP_API_KEY,
    env: import.meta.env.VITE_WEBAPP_ENV,
    algoliaAppId: import.meta.env.VITE_WEBAPP_ALGOLIA_APP_ID,
    algoliaApiKey: import.meta.env.VITE_WEBAPP_ALGOLIA_API_KEY,
  }
  return {
    api: import.meta.env.VITE_WEBAPP_API,
    apiKey: import.meta.env.VITE_WEBAPP_API_KEY,
    env: import.meta.env.VITE_WEBAPP_ENV,
    algoliaAppId: import.meta.env.VITE_WEBAPP_ALGOLIA_APP_ID,
    algoliaApiKey: import.meta.env.VITE_WEBAPP_ALGOLIA_API_KEY,
  }
}

const ConfigContext = createContext<{
  config: MedipilotConfig | undefined
}>({ config: undefined })

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
  const [config, setConfig] = useState<MedipilotConfig | undefined>(undefined)

  useEffect(() => {
    fetchConfig()
      .then((config) => {
        setConfig(config)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  if (!config) {
    return null
  }

  return (
    <ConfigContext.Provider value={{ config }}>
      {children}
    </ConfigContext.Provider>
  )
}

export const useConfig = () => {
  const { config } = useContext(ConfigContext)
  return config as MedipilotConfig
}
