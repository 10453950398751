import { LanguageSelect } from '@/components/language-select/LanguageSelect'
import { LocalStorageKeys } from '@/constants/LocalStorageKeys'
import { Routes } from '@/routing/routes'
import { useEffect } from 'react'

type Props = {
  sidebar?: React.ReactNode
  children?: React.ReactNode
  resetForm?: () => void
}

export const PageLayout = ({ sidebar, children, resetForm }: Props) => {
  const reset = () => {
    console.log('resetting')
    localStorage.removeItem(LocalStorageKeys.FormState)
    localStorage.removeItem(LocalStorageKeys.FormData)
    localStorage.removeItem(LocalStorageKeys.ExpirationTime)
    setTimeout(() => {
      resetForm()
      window.location.href = Routes.Root
    }, 200)
  }

  useEffect(() => {
    const expirationTime = localStorage.getItem(LocalStorageKeys.ExpirationTime)
    console.log('time', expirationTime)
    if (expirationTime) {
      const currentTime = new Date().getTime()
      if (expirationTime && currentTime > parseInt(expirationTime)) {
        reset()
      }
    }
  }, [])

  return (
    <div
      className="w-screen h-screen flex flex-col p-0 pt-4 gap-4 bg-secondary relative md:px-12 md:py-10 md:gap-10"
      style={{
        backgroundImage: 'url(/background.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Header */}
      <div className="flex justify-between items-center px-4 md:px-0">
        <div className="flex gap-3 items-center cursor-pointer" onClick={reset}>
          <img src="/logo.svg" alt="logo" className="h-7" />
          <h1 className="font-title2 font-bold">Medipilot</h1>
          <p className="font-tag uppercase px-2 py-1 bg-base-black text-base-white rounded-sm">
            Beta
          </p>
        </div>

        <LanguageSelect />
      </div>

      {/* Content */}
      <div className="flex-1 flex flex-col md:rounded-lg overflow-auto md:flex-row bg-base-white shadow-[0px_28px_14px_0px_#00000008,0px_12px_6px_0px_#00000003,0px_4px_4px_0px_#00000008]">
        {sidebar ? (
          <div className="flex md:border-r-sm border-neutral-400 md:p-12">
            {sidebar}
          </div>
        ) : null}

        <div className='flex flex-1 p-4 justify-center overflow-auto md:p-12'>
          {children}
        </div>
      </div>
    </div>
  )
}
