import {
  useQuery
} from 'react-query'
import { CategoryType } from '../form/form.types'
import { StaleTime } from '@/constants/Staletime'
import { useAxios } from '@/hooks/useAxios'
import { useLanguage } from '@/translations/hooks/useLanguage'

export type CategoryResponse = CategoryType[]

export const useCategories = () => {
  const axios = useAxios()
  const { language } = useLanguage()

  const url = 'categories'

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['categories', language],
    queryFn: async () => {
      const response = await axios.get<CategoryResponse>(url)
      return response.data
    },
    select: (response) => {
      return response
        .sort((a, b) => {
          return a.localisedName.localeCompare(b.localisedName)
        })
    },
    staleTime: StaleTime.ONE_HOUR,
  })

  return { categories: data, isLoading, error, refetch }
}
