import { AnalyzerResult, Severity } from '@/form/form.types'
import { t } from 'i18next'

type Props = {
  reportEntry: AnalyzerResult
}

export const ReportCard = ({ reportEntry }: Props) => {
  const relatedProducts =
    reportEntry.products?.length > 0
      ? reportEntry.products
        .map((product) => product.productDescription)
        .join(',\n')
      : t('steps.report.noProducts')

  if (
    reportEntry?.interaction === undefined ||
    reportEntry?.interaction === ''
  ) {
    return null
  }

  return (
    <div
      className={`flex flex-col gap-4 rounded-md overflow-hidden ${getBackgroundColor(reportEntry?.severity, false)}`}
    >
      <div
        className={`flex justify-between items-center py-2 px-6 ${getBackgroundColor(reportEntry?.severity, true)}`}
      >
        <p
          className={`font-body2 font-semibold ${getTextColor(reportEntry?.severity)}`}
        >
          {reportEntry.severity}
        </p>
        <p
          className={`font-body2 font-semibold ${getTextColor(reportEntry?.severity)}`}
        >
          {reportEntry.category}
        </p>
      </div>

      <div className="flex flex-col gap-3 px-6">
        <div className="flex gap-4 py-3">
          <p className="font-body2 font-semibold min-w-[100px] flex-shrink-0">
            {t('steps.report.problem')}
          </p>
          <div dangerouslySetInnerHTML={{ __html: reportEntry.interaction }} />
        </div>
        <div className="h-0 border border-solid border-base-black opacity-10" />
        <div className="flex gap-4 py-3">
          <p className="font-body2 font-semibold min-w-[100px] flex-shrink-0">
            {t('steps.report.advice')}
          </p>
          <div dangerouslySetInnerHTML={{ __html: reportEntry.intervention }} />
        </div>
        <div className="h-0 border border-solid border-base-black opacity-10" />
        <div className="flex gap-4 py-3">
          <p className="font-body2 font-semibold min-w-[100px] flex-shrink-0">
            {t('steps.report.products')}
          </p>
          <p className="font-body2 whitespace-pre-line">{relatedProducts}</p>
        </div>
      </div>
    </div>
  )
}

const getBackgroundColor = (severity?: Severity, highlighted?: boolean) => {
  switch (severity) {
    case Severity.A:
      return highlighted ? `bg-severity-a` : `bg-severity-a bg-opacity-50`
    case Severity.B:
      return highlighted ? `bg-severity-b` : 'bg-severity-b bg-opacity-50'
    case Severity.C:
      return highlighted ? `bg-severity-c` : 'bg-severity-c bg-opacity-50'
    case Severity.D:
      return highlighted ? `bg-severity-d` : 'bg-severity-d bg-opacity-50'
    default:
      return highlighted
        ? `bg-severity-total`
        : 'bg-severity-total bg-opacity-50'
  }
}

const getTextColor = (severity?: Severity) => {
  switch (severity) {
    case Severity.A:
      return `text-text-severity-a`
    case Severity.B:
      return `text-text-severity-b`
    case Severity.C:
      return `text-text-severity-c`
    case Severity.D:
      return `text-text-severity-d`
    default:
      return `text-neutral`
  }
}
